import React from 'react'
import { Styling } from './ModuleCard.styled'
import {FiSliders} from 'react-icons/fi'

export default function ModuleCard({module, index, isFakeProgramme}) {
    return (
        <Styling>
            {isFakeProgramme === true ? "" : <div className="capsule"><FiSliders /> Lesson {index + 1}</div>}
            <div className="title">{module.moduleTitle}</div>
            <div className="text">{module.moduleDescription}</div>
        </Styling>
    )
}
