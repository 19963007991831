import styled from "styled-components";
import ArrowRight from "../../../images/arrow-right-black.svg";
const Styling = styled.div`
  width: 100%;
  background: var(--white);
  padding: 4rem 2rem;

  @media only screen and (min-width: 350px) {
    padding: 4rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 8rem;
  }

  .course-overview {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media only screen and (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }

  .course-overview-inner {
    position: relative;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      width: 80%;
      padding-left: 10%;
    }

    label {
      font-size: clamp(3rem, 2vw, 5rem);
      text-transform: uppercase;
      margin-bottom: 2rem;
      position: relative;
      display: block;

      @media only screen and (min-width: 1024px) {
        margin-bottom: 0;
        position: absolute;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
        left: 5%;
        top: 0;
      }
    }

    .text {
      position: relative;
      margin-bottom: 4rem;

      p,
      ul {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 2.5rem;

        @media only screen and (min-width: 1024px) {
          line-height: 1.5em;
          font-size: clamp(1.7rem, 1vw, 2rem);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        &:first-child {
          @media only screen and (min-width: 1024px) {
            font-size: clamp(1.7rem, 1.3vw, 2.8rem);
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      ul {
        li {
          width: calc(100% - 5rem);
          margin-bottom: 1rem;
          text-transform: uppercase;
          ${({ theme }) => theme.tomato("bold")}
          padding-left:2.5rem;
          position: relative;

          &:before {
            width: 15px !important;
            height: 15px !important;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: url(${ArrowRight});
            background-size: contain;
            background-repeat: no-repeat;
            margin-top: 0.5rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
`;

export { Styling };
