import styled from "styled-components";

const Styling = styled.div`
  width: 100%;
  background: var(--white);
  text-align: center;
  padding: 6rem 4rem;

  @media only screen and (min-width: 650px) {
    padding: 8rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 10rem;
  }

  .main-title {
    text-transform: uppercase;
    font-size: clamp(3rem, 3vw, 9rem);
    margin-bottom: 4rem;

    @media only screen and (min-width: 1024px) {
      margin-bottom: 8rem;
    }
  }

  .features-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4rem;

    @media only screen and (min-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (min-width: 1200px) {
      grid-gap: 8rem;
    }

    .icon {
      margin-bottom: 2rem;
      max-width: 100px;
      max-height: 100px;
    }

    .title {
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("bold")}
      font-size:clamp(2rem, 1.4vw, 8rem);
      margin-bottom: 2rem;
    }

    .text {
      font-size: 1.4rem;
      line-height: 2rem;

      @media only screen and (min-width: 1200px) {
        font-size: clamp(1.6rem, 0.8vw, 3rem);
        line-height: clamp(1.6rem * 1.5, 0.8vw * 1.5, 3rem * 1.5);
      }
    }
  }
`;

export { Styling };
