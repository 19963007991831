import React from "react";
import SnipcartButton from "../SnipcartButton/index.js";
import { Styling } from "./styled.js";

export default function AmplifyYourDjSkills({
  takeTheNextStepIntroText,
  title,
  getFormsEndpoint,
  interactiveProgrammeTakeNextStepHeading,
  isFakeProgramme,
  snipcart,
  slug,
}) {
  return (
    <Styling>
      <div className="main-title">
        {interactiveProgrammeTakeNextStepHeading
          ? interactiveProgrammeTakeNextStepHeading
          : "Take the next step"}
      </div>
      <div className="introText">{takeTheNextStepIntroText}</div>
      {isFakeProgramme === true ? (
        <button
          className={`snipcart-add-item capsule_button white border_hover`}
          data-item-id={snipcart.productId}
          data-item-price={snipcart.price}
          data-item-url={process.env.GATSBY_FRONTEND_URL + "/" + slug}
          data-item-shippable={false}
          data-item-description={snipcart.productDescription}
          data-item-image={snipcart.productImage.localFile.publicURL}
          data-item-name={snipcart.productName}
        >
          Buy now
        </button>
      ) : (
        <form action={getFormsEndpoint} method="POST">
          <div className="form-styles">
            <input
              type="text"
              name="name"
              placeholder="Name*"
              required
              className="col-2"
            />
            <input
              type="email"
              name="email"
              placeholder="Email*"
              required
              className="col-2"
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              required
              className={"col-2"}
            />
            {title === "Production Certificate" ||
            title === "Production Masterclass" ||
            title === "Creativity Unlocked" ? (
              <select name="study-option" className="col-2">
                <option selected disabled>
                  Study Option
                </option>
                <option className="option" value="Ableton">
                  Ableton
                </option>
                <option className="option" value="Logic">
                  Logic
                </option>
              </select>
            ) : (
              ""
            )}

            {title === "Club Ready" ? (
              <select name="study-option" className="col-2">
                <option selected disabled>
                  Study Option
                </option>
                <option className="option" value="Ableton">
                  Ableton
                </option>
              </select>
            ) : (
              ""
            )}
          </div>
          <input
            type="submit"
            className="capsule_button black"
            value="Submit"
          />
        </form>
      )}
    </Styling>
  );
}
