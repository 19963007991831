import React from 'react'
import { Styling } from './styled.js'

export default function KeyFeatures({features, uspsSectionTitle}) {
    return (
        <Styling>
              <div className="main-title">{uspsSectionTitle ? uspsSectionTitle : 'Key Features' }</div>

              <ul className="features-wrapper">
                {features.map((feature, index) => { 
                    return (
                        <li key={`feature_${index}`}>
                            <img className="icon" src={feature?.icon?.localFile?.publicURL} alt="Icon" />
                            <div className="title">{feature.title}</div>
                            <div className="text">{feature.text}</div>
                        </li>
                    )
                })}
              </ul>
        </Styling>
    )
}
