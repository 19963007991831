import React from "react";
import { graphql } from "gatsby";
import HeroProgrammesWithBox from "../components/reusable/HeroProgrammesWithBox";
import CourseOverview from "../components/reusable/CourseOverview";
import Video from "../components/reusable/Video";
import Modules from "../components/reusable/Modules";
import KeyFeatures from "../components/reusable/KeyFeatures";
import CourseTutorSlider from "../components/reusable/CourseTutorSlider";
import IsThisCourseForMe from "../components/reusable/IsThisCourseForMe";
import TakeTheNextStep from "../components/reusable/TakeTheNextStep";
import MethodsOfStudy from "../components/reusable/MethodsOfStudy";
import Testimonials from "../components/page-components/Homepage/Testimonials";
// import TestimonialsSlider from '../components/reusable/TestimonialsSlider'
import Seo from "../components/global/seo";

export default function DjMasterclass({ data }) {
  const pageData = data.wpProgramme.interactive_programme;

  return (
    <div>
      <Seo title={data.wpProgramme.seo.title} description={data.wpProgramme.seo.metaDesc} />
      <HeroProgrammesWithBox
        image={pageData?.detailPageInteractive?.heroImage?.localFile}
        title={data.wpProgramme.title}
        boxText={pageData.archiveFieldsCopy}
      />
      <CourseOverview
        title={data.wpProgramme.title}
        sidebox={"register_interest"}
        courseOverview={pageData.detailPageInteractive.courseOverview}
        getFormsEndpoint={data.wpProgramme.getFormsEndpoint.getformsEndpointUrl}
      />
      <Video
        video={pageData.detailPageInteractive.video}
        addBottomColor={true}
        bottomColor={"black"}
      />
      <Modules
        title="Modules"
        introText={pageData.detailPageInteractive.moduleIntroText}
        modules={pageData.detailPageInteractive.interactiveProgrammeModules}
      />
      <KeyFeatures features={pageData.detailPageInteractive.interactiveProgrammeUsps} />
      <CourseTutorSlider tutors={pageData.detailPageInteractive.interactiveProgrammeInstructors} />
      {pageData.detailPageInteractive.interactiveProgrammeMethodsOfStudy !== null &&
      pageData.detailPageInteractive.interactiveProgrammeMethodsOfStudyImage !== null ? (
        <MethodsOfStudy
          methods={pageData.detailPageInteractive.interactiveProgrammeMethodsOfStudy}
          image={pageData.detailPageInteractive.interactiveProgrammeMethodsOfStudyImage}
        />
      ) : (
        ""
      )}
      {pageData.detailPageInteractive.interactiveProgrammeIsThisCourseForMe !== null ? (
        <IsThisCourseForMe
          items={pageData.detailPageInteractive.interactiveProgrammeIsThisCourseForMe}
        />
      ) : (
        ""
      )}

      {pageData.detailPageInteractive.interactiveProgrammeTestimonials !== null ? (
        <Testimonials
          testimonialsData={pageData.detailPageInteractive.interactiveProgrammeTestimonials}
        />
      ) : (
        ""
      )}
      <TakeTheNextStep
        title={data.wpProgramme.title}
        takeTheNextStepIntroText={
          pageData.detailPageInteractive.interactiveProgrammeTakeNextStepIntroText
        }
        getFormsEndpoint={data.wpProgramme.getFormsEndpoint.getformsEndpointUrl}
      />
    </div>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpProgramme(id: { eq: $id }) {
      title
      slug
      seo {
        title
        metaDesc
      }
      getFormsEndpoint {
        getformsEndpointUrl
      }
      interactive_programme {
        archiveFieldsCopy
        detailPageInteractive {
          courseOverview
          video
          interactiveProgrammeMethodsOfStudy {
            text
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          interactiveProgrammeMethodsOfStudyImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          interactiveProgrammeTestimonials {
            testimonial {
              ... on WpTestimonial {
                slug
                testimonialFields {
                  archiveQuote
                  profileImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                      }
                    }
                  }
                }
                title
              }
            }
          }
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          moduleIntroText
          interactiveProgrammeModules {
            moduleTitle
            moduleDescription
          }
          interactiveProgrammeTakeNextStepIntroText
          interactiveProgrammeUsps {
            text
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          interactiveProgrammeIsThisCourseForMe {
            answer
            question
          }
          interactiveProgrammeInstructors {
            instructor {
              ... on WpInstructor {
                title
                instructor {
                  biography
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
