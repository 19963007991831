import styled from "styled-components";

const Styling = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background: var(--white);

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: rgba(0, 0, 0, 0.6);
      z-index: 10;
    }
  }

  .hero {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 8rem;

    * {
      height: 100%;
    }
  }

  .parallax-outer {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .parallax-inner {
      height: 100%;
    }
  }

  .box_title {
    color: var(--white);
    width: 100%;
    position: relative;
    margin-top: 25vh;
    padding: 8rem 0 0 0;
    z-index: 100;

    @media only screen and (min-width: 1024px) {
      margin-top: 25vh;
      padding: 8rem 8rem 0 8rem;
      z-index: 100;
    }

    .main_title {
      font-size: clamp(5rem, 4vw, 10rem);
      letter-spacing: -0.09em;
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("regular")}
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      padding-left: 4rem;

      @media only screen and (min-width: 1024px) {
        padding-left: 0;
        margin-bottom: 8rem;
      }

      svg {
        margin-right: 0.2em;
        width: clamp(3rem, 3vw, 10rem);
      }
    }

    .box {
      background: var(--black);
      padding: 4rem;
      width: 100%;
      font-size: clamp(2rem, 1.4vw, 4rem);
      line-height: clamp(2rem * 1.25, 1.4vw * 1.25, 4rem * 1.25);

      @media only screen and (min-width: 1024px) {
        font-size: clamp(2rem, 1.4vw, 4rem);
        line-height: clamp(2rem * 1.25, 1.4vw * 1.25, 4rem * 1.25);
        letter-spacing: -0.02em;
        ${({ theme }) => theme.tomato("regular")}
        width:65%;
        padding: 8rem;
      }

      .capsule_button {
        margin-top: 2rem;
      }
    }
  }
`;

export { Styling };
