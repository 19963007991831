import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
display: flex;
background:var(--grey_light);
position: relative;

    @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    }


    .text,
    .image-wrapper{
    width: 50%;

        @media only screen and (max-width: 1024px) {
        width:100%;
        }

    }

    .image-wrapper{
    order:1;

        @media only screen and (max-width: 1024px) {
        display:none;
        }

        .gatsby-image-wrapper{
        width: 100%;
        height:100%;
        }
    }

    .text{
    margin:auto;
    padding:10%;
    order:2;

        .inner_text{
        line-height: 1.5em;
        font-size:clamp(1.4rem, 1vw, 2rem);

            .method{
            margin-bottom: 4rem;
            position: relative;

                &:last-child{
                margin-bottom: 0;
                }

                .list-title{
                font-weight:bold;
                text-transform: uppercase;
                margin-bottom: 1rem;

                    .icon{
                    margin-right: 1rem;
                    width: clamp(1.4rem/1.25, 1vw/1.25, 2rem/1.25);
                    }
                }

                .list-text{
                    ul{
                    padding-left:2.5rem;
                    position: relative;

                        li{
                        margin-bottom: 1rem;
                        position: relative;
                        
                            &:before{
                            width:5px;
                            height:5px;
                            border-radius: 100%;
                            background:var(--black);
                            content:"";
                            position: absolute;
                            left:-2rem;
                            top:1rem;
                            }
                        }

                    }
                }
            }

        }

        .title{
        font-size:clamp(2.2rem, 3vw, 4rem);
        text-transform: uppercase;
        letter-spacing: -0.07em;
        margin-bottom: 4rem;
        ${({theme}) => theme.tomato('regular')}  
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;

            span{
            width: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            }

            svg{
            width:clamp(2.2rem/1.3, 3vw/1.3, 4rem/1.3);
            margin-right:1rem;

                path{
                    fill:var(--black);
                }

            }

        }

    }

`

export { Styling }