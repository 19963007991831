import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import parse from 'html-react-parser'
import Arrow from '../../../images/arrow_down_right.inline.svg'
export default function MethodsOfStudy({methods, image}) {

    return (
        <Styling>
              <div className="image-wrapper">
                <GatsbyImage image={getImage(image?.localFile)} className="image" alt={'test'} />
              </div>
              <div className="text">
                <h2 className="title">
                    <span>Methods</span> 
                    <span><Arrow /> of study</span>
                </h2>
                <ul className="inner_text">
                    {methods.map((method, index) => {
                        return(
                            <div class="method">
                                <div className="list-title"><img src={method?.icon?.localFile?.publicURL} className="icon" />{method.title}</div>
                                <div className="list-text">{parse(method.text)}</div>
                            </div>
                        )
                    })}
                </ul>
              </div>
        </Styling>
    )
}
