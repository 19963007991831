import React from 'react'
import { Styling } from './styled.js'
import parse from 'html-react-parser'
import GetCourseInfo from '../Sidebox/Sidebox_GetCourseInfo'
import JoinTheProgramme from '../Sidebox/Sidebox_JoinTheProgramme'
import RegisterInterest from '../Sidebox/Sidebox_RegisterInterest'
import SnipcartSidebar from '../Sidebox/Sidebox_SnipcartButton'

export default function CourseOverview({sidebox, courseOverview, joinTheProgramme, courseInfo, path, title, getFormsEndpoint, isFakeProgramme, courseOverviewHeading, snipcart, slug}) {
    return (
        <Styling>
            <div id="course-overview" className="course-overview">
                <div className="course-overview-inner">
                    <label htmlFor="course-overview">{courseOverviewHeading ? courseOverviewHeading : 'Course Overview' }</label>
                    <div className="text">{parse(courseOverview)}</div>

                </div>

                {
                    isFakeProgramme === true 
                        ? <SnipcartSidebar snipcart={snipcart} slug={slug} />
                        : <RegisterInterest title={title} getFormsEndpoint={getFormsEndpoint} /> 
                }

                {/* {
                    sidebox === 'get_course_info' ? <GetCourseInfo title={title} />
                    : sidebox === "join_the_programme" ? <JoinTheProgramme joinTheProgrammeData={joinTheProgramme} courseInfo={courseInfo} path={path} title={title} />
                    : sidebox === "register_interest" ? <RegisterInterest title={title} getFormsEndpoint={getFormsEndpoint} />
                    : ""
                } */}
                
                
            </div>
        </Styling>
    )
}
