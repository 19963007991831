import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
background:var(--white);
text-align:center;
padding:4rem;

    @media only screen and (min-width: 768px) {
        padding:8rem;
    }

    @media only screen and (min-width: 1200px) {
    padding:10rem;
    }

    .main-title{
    text-transform: uppercase;
    margin-bottom: 4rem;;
    ${({theme}) => theme.tomato('regular')}  
    letter-spacing: -0.09em;
    font-size:3rem;
    
        @media only screen and (min-width: 450px) {
        font-size:4rem;
        }
        @media only screen and (min-width: 560px) {
        font-size:5rem;
        }
        @media only screen and (min-width: 768px) {
        font-size:6rem;
        }
        
        @media only screen and (min-width: 1024px) {
        font-size:clamp(8rem, 5vw, 25rem);    
        }

    }

    .introText{
    font-size:clamp(1.6rem, 0.8vw, 3rem);
    line-height:clamp(1.6rem * 1.5, 0.8vw * 1.5, 3rem * 1.5);
    max-width: 600px;
    margin:0 auto 4rem auto;
    }

    .capsule_button{
    background:var(--black);
    color:var(--white);

        &:hover{
        color:var(--black);
        background:var(--white);
        border:1px solid var(--black) !important;
        }

    }
    
    form{
    max-width:700px;
    margin:0 auto;
    }

`

export { Styling }