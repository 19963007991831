import styled from 'styled-components'

const Styling = styled.article`
background:var(--white);
color:var(--black);
padding:3rem;
height: 100%;
border-radius: 1rem;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: flex-end;
align-content: flex-start;


    .capsule{
    padding:0.4rem 0.8rem;
    background:var(--green);
    display: inline-flex;
    border-radius: 1rem;
    font-size:1.5rem;
    font-weight:bold;
    margin-bottom: 2rem;
    
        svg{
        width: 1r.3em;
        margin-right:0.5rem;
        }

    }

    .title{
    text-transform:uppercase;
    ${({theme}) => theme.tomato('bold')}  
    font-size:clamp(2rem, 1.4vw, 8rem);
    margin-bottom: 2rem;
    width:100%;
    }

    .text{
    font-size:clamp(1.6rem, 0.8vw, 3rem);
    line-height:clamp(1.6rem * 1.5, 0.8vw * 1.5, 3rem * 1.5);
    }

`

export { Styling }