import React, { useEffect } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArrowRight from "../../../images/arrow_down_right.inline.svg";
import { RellaxWrapper } from "react-rellax-wrapper";
import RegisterInterestScrollButton from "../../global/RegisterInterestScrollButton.js";

export default function HeroProgrammesWithBox({ image, title, boxText }) {
  const theImage = getImage(image);
  //get gatsby route url
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  console.log(url);

  return (
    <Styling>
      <div className="box_title">
        <h1 className="main_title">
          <ArrowRight /> {title}
        </h1>
        <div className="box">
          <div>{boxText}</div>
          {url !== "/london" && (
            <RegisterInterestScrollButton
              hash={"#register-interest"}
              borderColor={"white"}
              text={"Do you want a career in music?"}
            />
          )}
        </div>
      </div>
      <div className="hero">
        <RellaxWrapper speed={-9}>
          <GatsbyImage image={theImage} className="image" alt={"test"} />
        </RellaxWrapper>
      </div>
    </Styling>
  );
}
