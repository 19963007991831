import React from "react";
import { Styling } from "./styled.js";
import { StylingWrapper } from "../styled.js";
import Arrow from "../../../../images/arrow_down.inline.svg";
import Logo from "../../../../images/logo2.inline.svg";

export default function COMPONENT({ data, title, getFormsEndpoint }) {
  return (
    <StylingWrapper>
      <Styling>
        <div className="title" id="register-interest">
          Register Interest <Arrow />
        </div>
        <form action={getFormsEndpoint} method="POST">
          <div className="form-styles">
            <input type="text" name="name" className="col-1" placeholder="Name*" required />
            <input type="email" name="email" className="col-1" placeholder="Email*" required />
            <input type="text" name="phone" className="col-1" placeholder="Phone" />
            {title === "Production Certificate" ||
            title === "Production Masterclass" ||
            title === "Creativity Unlocked" ? (
              <select className="col-1" name="study-option">
                <option selected disabled>
                  Study Option
                </option>
                <option className="option" value="Ableton">
                  Ableton
                </option>
                <option className="option" value="Logic">
                  Logic
                </option>
              </select>
            ) : (
              ""
            )}
          </div>
          <div className="bottom">
            <input type="submit" className="capsule_button black" value="Enquire now" />
            <Logo />
          </div>
        </form>
      </Styling>
    </StylingWrapper>
  );
}
