import styled from 'styled-components'

const Styling = styled.section`
width: 100%;
background:var(--black);
color:var(--white);
padding:4rem;
text-align:center;

    @media only screen and (min-width: 1400px) {
    padding:16rem;
    }

    .main-title{
    text-transform: uppercase;
    font-size:clamp(3rem, 3vw, 9rem);
    margin-bottom:4rem;
    }

    .introText{
    margin-bottom:4rem;
    max-width: 50rem;
    margin:0 auto 8rem auto;
    }

    .modules-wrapper{
    width: 100%;
    display: grid;
    grid-gap:2rem;
    margin-bottom: 4rem;
    grid-template-columns: repeat(1, 1fr);
    
        @media only screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

`

export { Styling }