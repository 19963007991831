import React, {useState, useEffect} from "react"
import { Styling } from './styled.js'
import ModuleCard from './ModuleCard'

export default function Modules({title, introText, modules, moduleIntroHeading, isFakeProgramme}) {

       
  // Array of all products
  const allModules =  modules

  // State for the list
  const [list, setList] = useState([...allModules.slice(0, 6)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allModules.length > 6)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

   // Handle loading more articles
   useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allModules.length
      const nextResults = isMore
        ? allModules.slice(currentLength, currentLength + 6)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allModules.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

    return (
        <Styling>
            <h3 className="main-title">{moduleIntroHeading ? moduleIntroHeading : title}</h3>  
            <div className="introText">{introText}</div>
            <ul className="modules-wrapper">
                {list.map((module, index) => {
                    return (
                        <li key={`module_${index}`}>
                            <ModuleCard module={module} index={index} isFakeProgramme={isFakeProgramme} />
                        </li>
                    )
                })}
            </ul>
            {hasMore ? (
                <button className="capsule_button white" onClick={handleLoadMore}>Load More</button>
            ) : (
                ''
            )}
        </Styling>
    )
}
