import React from 'react'
import { Styling } from './styled.js'
import { StylingWrapper } from '../styled.js'
import Arrow from '../../../../images/arrow_down.inline.svg'
import Logo from '../../../../images/logo2.inline.svg'

export default function COMPONENT({data, title, getFormsEndpoint, snipcart, slug}) {
    return (
        <StylingWrapper>
            <Styling>
                <div className="title">
                    Register Now <Arrow />
                </div>

                <div className="sidebar-text">
                    1 x {snipcart.productName} - £{snipcart.price.toFixed(2)}
                </div>

                <button 
                className={`snipcart-add-item capsule_button black border_hover`}
                data-item-id={snipcart.productId}
                data-item-price={snipcart.price}
                data-item-url={process.env.GATSBY_FRONTEND_URL+'/'+slug}
                data-item-shippable={false}
                data-item-description={snipcart.productDescription}
                data-item-image={snipcart.productImage.localFile.publicURL}
                data-item-name={snipcart.productName}>
                  Buy now
                </button>
            </Styling>
        </StylingWrapper>
    )
}
