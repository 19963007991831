import styled from 'styled-components'
import ArrowRight from '../../../images/arrow-right-black.svg'

const StylingWrapper = styled.div`
box-shadow: 0px 16px 47px rgba(0, 0, 0, 0.05), 0px 8.1px 20.4891px rgba(0, 0, 0, 0.03375), 0px 3.2px 7.6375px rgba(0, 0, 0, 0.025), 0px 0.7px 2.71719px rgba(0, 0, 0, 0.01625);
border-radius: 8px;
padding:4rem;
width:100%;

    @media only screen and (min-width: 1024px) {
    width: 30%;
    margin-left:8rem;
    }

    .title{
    text-transform: uppercase;
    font-size:clamp(1.8rem, 1.5vw, 2.8rem);
    display: inline-flex;
    align-items:center;
    margin-bottom: 4rem;

        svg{
        width:clamp(1.8rem, 1vw, 2.8rem);
        margin-left:0.5rem;
        height: auto;
        }

    }

    .sidebar-text{
    width: 100%;
    margin-bottom: 4rem;
    text-transform:uppercase;
    ${({theme}) => theme.tomato('bold')}  
    }

    svg{
        path{
            fill:var(--black);
        }
    }

    .capsule_button{
    margin-bottom: 0;
    cursor: pointer;
    margin-right: 2rem;
    }

    .bottom{
    display: flex;
    align-items: center;
    }
    
    .text{
    margin-bottom: 4rem;

        ul{
            li{
            width: 100%;
            margin-bottom: 1rem;
            text-transform:uppercase;
            ${({theme}) => theme.tomato('bold')}  
            padding-left:2.5rem;
            position: relative;

                &:last-child{
                margin-bottom: 0;
                }

                &:before{
                width:15px !important;
                height:15px !important;
                content:"";
                position: absolute;
                left:0;
                top:-4px;
                background:url(${ArrowRight});
                background-size:contain;
                background-repeat:no-repeat;
                margin-top:0.5rem;
                margin-right:1rem;
                }

            }
        }


    }
`

export { StylingWrapper }